<template>
  <sm-long-process
    :isLoadingPage="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :controllerName="controllerName"
    :form="form"
    :updateFields="updateFields"
    @clearForm="handleClearForm"
  >
    <template #top-panel>
      <sm-report
        :reportName="reportName"
        class="debtor-notification__report"
      />
      <h2 class="debtor-notification__title">
        {{ longProcessTitle }}
      </h2>
    </template>
  </sm-long-process>
</template>

<script>
// components
import SmLongProcess from '@/components/views/SmLongProcess.vue';
import SmReport from '@/components/views/SmReport.vue';

export default {
  name: 'DebtorList',

  components: {
    SmLongProcess,
    SmReport,
  },

  data() {
    return {
      isLoadingPage: false,
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Список должников',
        },
      ],
      pageHeader: 'Список должников',
      longProcessTitle: 'Оповестить должников по СМС',
      controllerName: 'CustomersNotificationAboutDebtProcessor',
      form: {
        sendSms: true,
      },
      initialForm: {
        sendSms: true,
      },
      updateFields: [
        {
          type: 'checkbox',
          key: 'sendSms',
          label: 'Отправить СМС?',
        },
      ],
      reportName: 'CustomersDebtsNotificationReport',
    };
  },

  methods: {
    handleClearForm() {
      this.form = this.lodash.cloneDeep(this.initialForm);
    },
  },
};
</script>

<style lang='scss'>
.debtor-notification__title {
  margin: 30px 0;
}

.debtor-notification__report .report__table {
  max-height: 500px;
}
</style>